@tailwind base;
@tailwind components;
@tailwind utilities;



html,body{
    overflow-x: hidden;
}

.gradientBg{
    background: linear-gradient(99deg,#103559 0%, #2abed3 100%);
}
.picBg{
    position: absolute;
    height: 100%;
    width: 100%;
    
    /* img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    } */
}
.sectionText{
    position: absolute;
    height: 100%;
    top: 30%;
    bottom: 50%;
    color: black;
    padding: 0 1.3rem;
}
.hero{
    width: 100%;
    min-height: 100vh;

    background: linear-gradient(rgba(8,0,58,0.7),rgba(8,0,58,0.7)), url('../src/assets//pic/bn14.jpeg');
    background-size: cover;
    background-position: center;
}